import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SakuraText from "../components/sakura-text"

const PaddedBotDiv = styled("div")`
  padding-bottom: 30px;
`
const workAroundMarginTopCss = css`
  @media (max-width: 992px) {
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    margin-top: -30px;
  }
  @media (max-width: 576px) {
    margin-top: -100px;
  }
  @media (max-width: 380px) {
    margin-top: -180px;
  }
`
const SqBtn = styled("div")`
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  background: 0 0;
  text-decoration: none;
  font-weight: 500;
  padding: 0.3em 1em;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  position: relative;
  transition: all 300ms ease 0ms;
  display: inline-block;
  font-size: 20px;
  margin-bottom: 20px;
  width: 100%;
  :hover {
    text-decoration: none;
  }
  :visited {
    text-decoration: none;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
  }
`

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query vtechQuery {
      top: file(relativePath: { eq: "about-1-2-01-1.png" }) {
        childImageSharp {
          resize(width: 96) {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="櫻花消毒技術 - 櫻花家居服務"
        description="櫻花專業消毒 - 抗疫四式 病毒日日肆虐🦠到底點樣消毒先最全面😣？ 我地一齊睇下櫻花嘅《抗疫四式消毒》啦💫！！！"
      />
      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
          padding: 80px 0;
          align-items: center;
        `}
      >
        <Container>
          <Row>
            <Col xl={12}>
              <h1>
                <PaddedBotDiv>
                  <SakuraText text="櫻花消毒技術" size={28} />
                </PaddedBotDiv>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <PaddedBotDiv>
                <div
                  css={css`
                    text-align: center;
                  `}
                >
                  <div
                    css={css`
                      color: #5f4235;
                    `}
                  >
                    <h3>
                      <strong>櫻花專業消毒—抗疫四式</strong>
                    </h3>
                  </div>
                  <div>
                    <p>病毒日日肆虐🦠到底點樣消毒先最全面😣？</p>
                    <p>我地一齊睇下櫻花嘅《抗疫四式消毒》啦💫！！</p>
                  </div>
                </div>
              </PaddedBotDiv>
            </Col>
            <Col md={{ span: 3, offset: 1 }} xs={12}>
              <PaddedBotDiv>
                <img
                  css={css`
                    margin: 0 auto;
                    display: block;
                  `}
                  src={data.top.childImageSharp.resize.src}
                />
              </PaddedBotDiv>
            </Col>
          </Row>
          <Row>
            <iframe
              width="800"
              height="450"
              style={{ margin: "0 auto", border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allowTransparency={true}
              allowFullScreen={true}
              data-src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fsakurahomeservices%2Fvideos%2F303357774233366%2F&amp;show_text=0&amp;width=800"
              className=" lazyloaded"
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fsakurahomeservices%2Fvideos%2F303357774233366%2F&amp;show_text=0&amp;width=800"
            ></iframe>
          </Row>
          <Row css={workAroundMarginTopCss}>
            <Col md={6} xs={12}>
              <Link to="/抗疫四式第一式-relyon">
                <SqBtn
                  css={css`
                    color: #fc9201 !important;
                    border-color: #fc9201;
                  `}
                >
                  第一式《噴！》RelyOn Virkon 殺菌劑🇺🇸
                </SqBtn>
              </Link>
              <Link to="/抗疫四式第二式-hoci">
                <SqBtn
                  css={css`
                    color: #f900de !important;
                    border-color: #f900de;
                  `}
                >
                  第二式《抹！》 HOCI 次亜塩素酸🇯🇵
                </SqBtn>
              </Link>
            </Col>
            <Col md={6} xs={12}>
              <Link to="/抗疫四式第三式-PIE臭氧消毒裝置">
                <SqBtn
                  css={css`
                    color: #4691f8 !important;
                    border-color: #4691f8;
                  `}
                >
                  第三式《放！》 PIE 臭氧消毒裝置
                </SqBtn>
              </Link>
              <Link to="/抗疫四式第四式-日本東芝光觸媒">
                <SqBtn
                  css={css`
                    color: #01dcbc !important;
                    border-color: #01dcbc;
                  `}
                >
                  第四式《抗！》日本東芝光觸媒
                </SqBtn>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/booking" className="button">
                  <b>立即預約</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div
                css={css`
                  margin: 0 auto;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  padding-bottom: 30px;
                `}
              >
                <Link to="/消毒案例" className="button">
                  <b>消毒案例</b>
                  <span className="icon -right">
                    <RiArrowRightSLine />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
